<template lang="pug">
.ui-tooltip(ref="reference" @click="closeFloating")
  .reference
    slot
  Transition
    .floating(v-if="isHovered && !disabled && ($slots.content || content)" ref="floating" :style="[floatingStyles, maxWidth && `max-width: ${ maxWidth }px`]")
      span.arrow(ref="floatingArrow" :style="arrowStyles")
      slot(name="content" :close="closeFloating")
        span {{ content }}
</template>

<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import { useFloating, shift, autoUpdate, offset, arrow } from '@floating-ui/vue';
import { useElementHover  } from '@vueuse/core';

import type { PropType } from "vue";

type Side = 'top' | 'right' | 'bottom' | 'left';
type Alignment = 'start' | 'end';
type AlignedPlacement = `${Side}-${Alignment}`;

export default defineComponent({
  name: "UiTooltip",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    placement: {
      type: String as PropType<Side | AlignedPlacement>,
      default: 'bottom',
    },
    closeOnClick: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
  },
  setup(props, context) {

    const reference = ref(null);
    const floating = ref(null);
    const floatingArrow = ref(null);

    const isHovered = useElementHover(reference);

    const { floatingStyles, middlewareData } = useFloating(reference, floating, {
      open: isHovered,
      placement: props.placement,
      whileElementsMounted: autoUpdate,
      middleware: [
        offset(12),
        shift(),
        arrow({ element: floatingArrow, padding: 12 }),
      ],
    });

    const bottomArrow = {
      bottom: '-5px',
      transform: 'rotate(-135deg)',
    };

    const topArrow = {
      top: '-5px',
      transform: 'rotate(45deg)',
    };

    const rightArrow = {
      right: '-5px',
      transform: 'rotate(135deg)',
    };

    const leftArrow = {
      left: '-5px',
      transform: 'rotate(-45deg)',
    };

    const arrowStyles = computed<CSSStyleValue>(() => {
      const arrow = middlewareData.value.arrow || { x: 0, y: 0 };
      const placement = middlewareData.value.offset?.placement;

      if (arrow.x != null) {
        return {
          left: `${arrow.x || 0}px`,
          ...(placement === 'top' ? bottomArrow : topArrow),
        };
      }

      return {
        top: `${arrow.y || 0}px`,
        ...(placement?.includes('right') ? leftArrow : rightArrow),
      };
    });

    function closeFloating() {
      if (props.closeOnClick) isHovered.value = !isHovered.value
    }

    return {
      reference,
      floating,
      floatingStyles,
      floatingArrow,
      arrowStyles,
      isHovered,
      closeFloating,
    }
  }
})
</script>

<style scoped lang="scss">
.ui-tooltip {
  display: block;
}

.reference {
  display: flex;
  user-select: none;
}

.floating {
  background-color: white;
  font-size: 12px;
  line-height: 20px;
  position: relative;

  border: 1px solid #e4e7ed;
  border-radius: 4px;

  z-index: 4001;
  min-width: 10px;
  width: max-content;
  overflow-wrap: break-word;
  padding: 5px 11px;

  .arrow {
    position: absolute;
    width: 10px;
    height: 10px;
    z-index: -1;

    &:before {
      border: 1px solid #e4e7ed;
      background-color: white;
      right: 0;
      border-bottom-color: transparent !important;
      border-right-color: transparent !important;
      border-top-left-radius: 2px;

      position: absolute;
      width: 10px;
      height: 10px;
      z-index: -1;
      content: " ";
      box-sizing: border-box;
    }
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 50ms linear;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transition-delay: 50ms;
}
</style>
